/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "./src/styles/global.scss"
let initialScroll = true

export const onRouteUpdate = () => {
  if (initialScroll) {
    global.scrollTo(0, 0)
    initialScroll = false
  }
}

export const onClientEntry = () => {
  initialScroll = true
}
