exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-about-js": () => import("./../../../src/pages/company/about.js" /* webpackChunkName: "component---src-pages-company-about-js" */),
  "component---src-pages-company-career-js": () => import("./../../../src/pages/company/career.js" /* webpackChunkName: "component---src-pages-company-career-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-company-insights-js": () => import("./../../../src/pages/company/insights.js" /* webpackChunkName: "component---src-pages-company-insights-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-hire-team-co-build-js": () => import("./../../../src/pages/hire-team/co-build.js" /* webpackChunkName: "component---src-pages-hire-team-co-build-js" */),
  "component---src-pages-hire-team-co-lab-js": () => import("./../../../src/pages/hire-team/co-lab.js" /* webpackChunkName: "component---src-pages-hire-team-co-lab-js" */),
  "component---src-pages-hire-team-gcc-js": () => import("./../../../src/pages/hire-team/gcc.js" /* webpackChunkName: "component---src-pages-hire-team-gcc-js" */),
  "component---src-pages-hire-team-index-js": () => import("./../../../src/pages/hire-team/index.js" /* webpackChunkName: "component---src-pages-hire-team-index-js" */),
  "component---src-pages-hire-team-quick-shift-js": () => import("./../../../src/pages/hire-team/quick-shift.js" /* webpackChunkName: "component---src-pages-hire-team-quick-shift-js" */),
  "component---src-pages-hire-team-recruitment-on-demand-js": () => import("./../../../src/pages/hire-team/recruitment-on-demand.js" /* webpackChunkName: "component---src-pages-hire-team-recruitment-on-demand-js" */),
  "component---src-pages-hire-team-staff-augmentation-js": () => import("./../../../src/pages/hire-team/staff-augmentation.js" /* webpackChunkName: "component---src-pages-hire-team-staff-augmentation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-services-ai-ml-js": () => import("./../../../src/pages/services/ai-ml.js" /* webpackChunkName: "component---src-pages-services-ai-ml-js" */),
  "component---src-pages-services-custom-solution-js": () => import("./../../../src/pages/services/custom-solution.js" /* webpackChunkName: "component---src-pages-services-custom-solution-js" */),
  "component---src-pages-services-experience-design-js": () => import("./../../../src/pages/services/experience-design.js" /* webpackChunkName: "component---src-pages-services-experience-design-js" */),
  "component---src-pages-services-full-stack-js": () => import("./../../../src/pages/services/full-stack.js" /* webpackChunkName: "component---src-pages-services-full-stack-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-salesforce-js": () => import("./../../../src/pages/services/salesforce.js" /* webpackChunkName: "component---src-pages-services-salesforce-js" */),
  "component---src-pages-services-team-hire-js": () => import("./../../../src/pages/services/team-hire.js" /* webpackChunkName: "component---src-pages-services-team-hire-js" */),
  "component---src-pages-success-stories-cheeky-js": () => import("./../../../src/pages/success-stories/cheeky.js" /* webpackChunkName: "component---src-pages-success-stories-cheeky-js" */),
  "component---src-pages-success-stories-chrono-js": () => import("./../../../src/pages/success-stories/chrono.js" /* webpackChunkName: "component---src-pages-success-stories-chrono-js" */),
  "component---src-pages-success-stories-compd-js": () => import("./../../../src/pages/success-stories/compd.js" /* webpackChunkName: "component---src-pages-success-stories-compd-js" */),
  "component---src-pages-success-stories-debbie-js": () => import("./../../../src/pages/success-stories/debbie.js" /* webpackChunkName: "component---src-pages-success-stories-debbie-js" */),
  "component---src-pages-success-stories-diall-js": () => import("./../../../src/pages/success-stories/diall.js" /* webpackChunkName: "component---src-pages-success-stories-diall-js" */),
  "component---src-pages-success-stories-flexnest-js": () => import("./../../../src/pages/success-stories/flexnest.js" /* webpackChunkName: "component---src-pages-success-stories-flexnest-js" */),
  "component---src-pages-success-stories-fluent-js": () => import("./../../../src/pages/success-stories/fluent.js" /* webpackChunkName: "component---src-pages-success-stories-fluent-js" */),
  "component---src-pages-success-stories-index-js": () => import("./../../../src/pages/success-stories/index.js" /* webpackChunkName: "component---src-pages-success-stories-index-js" */),
  "component---src-pages-success-stories-ivy-level-js": () => import("./../../../src/pages/success-stories/ivy-level.js" /* webpackChunkName: "component---src-pages-success-stories-ivy-level-js" */),
  "component---src-pages-success-stories-livetake-js": () => import("./../../../src/pages/success-stories/livetake.js" /* webpackChunkName: "component---src-pages-success-stories-livetake-js" */),
  "component---src-pages-success-stories-lustlab-js": () => import("./../../../src/pages/success-stories/lustlab.js" /* webpackChunkName: "component---src-pages-success-stories-lustlab-js" */),
  "component---src-pages-success-stories-owl-protocol-js": () => import("./../../../src/pages/success-stories/owl-protocol.js" /* webpackChunkName: "component---src-pages-success-stories-owl-protocol-js" */),
  "component---src-pages-success-stories-pelt-8-js": () => import("./../../../src/pages/success-stories/pelt8.js" /* webpackChunkName: "component---src-pages-success-stories-pelt-8-js" */),
  "component---src-pages-success-stories-safe-gold-js": () => import("./../../../src/pages/success-stories/safe-gold.js" /* webpackChunkName: "component---src-pages-success-stories-safe-gold-js" */),
  "component---src-pages-success-stories-squeezee-js": () => import("./../../../src/pages/success-stories/squeezee.js" /* webpackChunkName: "component---src-pages-success-stories-squeezee-js" */),
  "component---src-pages-success-stories-stonovation-js": () => import("./../../../src/pages/success-stories/stonovation.js" /* webpackChunkName: "component---src-pages-success-stories-stonovation-js" */),
  "component---src-pages-success-stories-superbetter-js": () => import("./../../../src/pages/success-stories/superbetter.js" /* webpackChunkName: "component---src-pages-success-stories-superbetter-js" */),
  "component---src-pages-success-stories-tralli-js": () => import("./../../../src/pages/success-stories/tralli.js" /* webpackChunkName: "component---src-pages-success-stories-tralli-js" */),
  "component---src-pages-technologies-ai-ml-js": () => import("./../../../src/pages/technologies/ai-ml.js" /* webpackChunkName: "component---src-pages-technologies-ai-ml-js" */),
  "component---src-pages-technologies-backend-js": () => import("./../../../src/pages/technologies/backend.js" /* webpackChunkName: "component---src-pages-technologies-backend-js" */),
  "component---src-pages-technologies-cloud-services-js": () => import("./../../../src/pages/technologies/cloud-services.js" /* webpackChunkName: "component---src-pages-technologies-cloud-services-js" */),
  "component---src-pages-technologies-index-js": () => import("./../../../src/pages/technologies/index.js" /* webpackChunkName: "component---src-pages-technologies-index-js" */),
  "component---src-pages-technologies-iot-js": () => import("./../../../src/pages/technologies/iot.js" /* webpackChunkName: "component---src-pages-technologies-iot-js" */),
  "component---src-pages-technologies-mobile-app-js": () => import("./../../../src/pages/technologies/mobile-app.js" /* webpackChunkName: "component---src-pages-technologies-mobile-app-js" */),
  "component---src-pages-technologies-no-code-js": () => import("./../../../src/pages/technologies/no-code.js" /* webpackChunkName: "component---src-pages-technologies-no-code-js" */),
  "component---src-pages-technologies-security-js": () => import("./../../../src/pages/technologies/security.js" /* webpackChunkName: "component---src-pages-technologies-security-js" */),
  "component---src-pages-technologies-web-js": () => import("./../../../src/pages/technologies/web.js" /* webpackChunkName: "component---src-pages-technologies-web-js" */)
}

